<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-material-card
      color="primary"
    >
      <template v-slot:heading>
        <div class="display-2 font-weight-light">
          Scraper
        </div>

        <div class="subtitle-1 font-weight-light">
          Paramètres de lancement
        </div>
      </template>

      <v-form ref="form">
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="data.maxDailyCredits"
                :rules="[rules.required, rules.number]"
                label="Crédits quotidiens maximum"
                class="purple-input"
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="data.mots"
                label="Mots clés ou #référence"
                class="purple-input"
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-select
                v-model="data.Poste"
                :items="selectPoste"
                label="Poste"
                multiple
                chips
                deletable-chips
                persistent-hint
              />
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-select
                v-model="data.Region"
                :items="selectRegion"
                label="Région/Pays"
                item-text="text"
                item-value="value"
                chips
                deletable-chips
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-radio-group
                v-model="data.Region_m"
                row
              >
                <v-radio
                  label="Mobilité"
                  value="M"
                />
                <v-radio
                  label="Résidence"
                  value="R"
                />
              </v-radio-group>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-label for="tjmEnabled">
                Tarif max des freelances
              </v-label>
              <v-switch
                id="tjmEnabled"
                v-model="tjmEnabled"
                class="v-input--prepended"
              >
                <template #prepend>
                  <v-slider
                    v-model="tjm"
                    thumb-label="always"
                    :step="tjmStep"
                    :min="tjmMin"
                    :max="tjmMax"
                    :disabled="!tjmEnabled"
                    :hint="tjmText"
                    persistent-hint
                  />
                </template>
              </v-switch>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-label for="brutEnabled">
                Brut max des salariés
              </v-label>
              <v-switch
                id="brutEnabled"
                v-model="brutEnabled"
                class="v-input--prepended"
              >
                <template #prepend>
                  <v-slider
                    v-model="brut"
                    thumb-label="always"
                    :step="brutStep"
                    :min="brutMin"
                    :max="brutMax"
                    :disabled="!brutEnabled"
                    :hint="brutText"
                    persistent-hint
                  />
                </template>
              </v-switch>
            </v-col>

            <v-col
              cols="12"
            >
              <v-select
                v-model="data.Dossier"
                :items="selectDossier"
                label="Dossiers"
                multiple
                chips
                deletable-chips
                persistent-hint
                item-text="text"
                item-value="value"
              />
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <v-select
                v-model="data.typecv"
                :items="selectTypecv"
                label="Type CV"
                multiple
                chips
                deletable-chips
                persistent-hint
                item-text="text"
                item-value="value"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="data.Population"
                :items="selectPopulation"
                label="Populations"
                multiple
                chips
                deletable-chips
                persistent-hint
                item-text="text"
                item-value="value"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-checkbox
                v-model="data.CVrecent"
                value="2"
                label="Nouveaux CV (-3 jours)"
              />
              <v-checkbox
                v-model="data.CVrecent"
                value="1"
                label="Nouv + Màj (-2 mois)"
              />
            </v-col>

            <v-col
              cols="12"
              md="2"
            >
              <v-radio-group
                v-model="data.Population_m"
                row
              >
                <v-radio
                  label="Union"
                  value="U"
                />
                <v-radio
                  label="Intersection"
                  value="I"
                />
              </v-radio-group>
            </v-col>
            <!--
            <v-col cols="12">
              <v-textarea
                v-model="data.actions"
                class="purple-input"
                label="Actions"
              />
            </v-col>
            -->
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                :class="classes"
                class="mr-0"
                color="primary"
                fab
                :disabled="launched"
                @click="launch()"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="55"
                >
                  <v-icon
                    color="white"
                  >
                    {{ (launched ? 'mdi-rocket-launch' : 'mdi-rocket') }}
                  </v-icon>
                </v-progress-circular>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </base-material-card>
    <base-material-overlay-alert
      v-model="alertError"
      :type="alertType"
    >
      {{ alertErrorText }}
    </base-material-overlay-alert>
    <base-material-snackbar
      v-model="alertSnackbar"
      right
      top
      :type="alertSnackbarType"
    >
      {{ alertSnackbarText }}
    </base-material-snackbar>
  </v-container>
</template>

<script>
  import LaunchesService from '@/services/launches.service'

  export default {
    name: 'DashboardLaunch',
    props: {
      id: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      alertType: 'error',
      alertError: false,
      alertErrorText: 'Une erreur est survenue',
      alertSnackbar: false,
      alertSnackbarText: 'Lancé avec succès',
      alertSnackbarType: 'success',
      launched: false,
      rules: {
        required: value => !!value || 'Champ requis',
        number: value => ((Math.sign(parseInt(value)) === 1) && (Number.isInteger(parseFloat(value))) && !isNaN(value)) || 'La valeur doit être un entier positif',
      },
      selectPoste: ['Administrateur BD', 'Administrateur ERP', 'Administrateur réseaux', 'Administrateur système', 'Administrateur produits', 'Analyste', 'Analyste d\'exploitation', 'Analyste programmeur', 'Analyste réalisateur', 'Analyste système', 'Architecte', 'Architecte BD', 'Architecte matériel', 'Architecte réseaux', 'Architecte SI', 'Architecte technique', 'Assistant aux utilisateurs', 'Assistant à maîtrise d\'ouvrage', 'Assistant à maîtrise d\'oeuvre', 'Auditeur', 'Chargé d\'affaire', 'Chef de projet', 'Chercheur en informatique', 'Concepteur BD', 'Concepteur multimédia', 'Concepteur télématique', 'Consultant', 'Consultant fonctionnel', 'Consultant formateur', 'Consultant technique', 'Développeur', 'Directeur informatique', 'Directeur de projet', 'Directeur technique', 'Directeur commercial', 'Expert', 'Infographiste', 'Ingénieur avant-vente', 'Ingénieur après-vente', 'Ingénieur commercial', 'Ingénieur d\'études', 'Ingénieur d\'exploitation', 'Ingénieur de développement', 'Ingénieur de production', 'Ingénieur qualité/méthodes', 'Ingénieur réseaux', 'Ingénieur système', 'Ingénieur validation', 'Maquettiste PAO', 'Pupitreur/Pilote', 'Rédacteur technique', 'Responsable de comptes', 'Responsable maintenance', 'Responsable d\'exploitation', 'Référenceur', 'Support utilisateurs', 'Technicien micro / réseaux', 'Technicien d\'exploitation', 'Technicien help desk', 'Urbaniste', 'Webmaster', 'Webplanneur'],
      selectRegion: [
        { value: 'R3', text: 'Auvergne-Rhône-Alpes' },
        { value: 'R5', text: 'Bourgogne-Franche-Comté' },
        { value: 'R6', text: 'Bretagne' },
        { value: 'R7', text: 'Centre' },
        { value: 'R9', text: 'Corse' },
        { value: 'R1', text: 'Grand-Est' },
        { value: 'R17', text: 'Hauts-de-France' },
        { value: 'R12', text: 'Ile de France' },
        { value: 'R4', text: 'Normandie' },
        { value: 'R2', text: 'Nouvelle Aquitaine' },
        { value: 'R13', text: 'Occitanie' },
        { value: 'R21', text: 'PACA' },
        { value: 'R18', text: 'Pays de Loire' },
        { value: 'R23', text: 'DOMTOM - Guadeloupe' },
        { value: 'R28', text: 'DOMTOM - Guyane' },
        { value: 'R27', text: 'DOMTOM - La Réunion' },
        { value: 'R25', text: 'DOMTOM - Martinique' },
        { value: 'R26', text: 'DOMTOM - Mayotte' },
        { value: 'Z99', text: 'PAYS - Autres pays' },
        { value: 'Z32', text: 'PAYS - BELGIQUE' },
        { value: 'Z35', text: 'PAYS - FRANCE' },
        { value: 'Z33', text: 'PAYS - LUXEMBOURG' },
        { value: 'Z34', text: 'PAYS - SUISSE' },
      ],
      selectDossier: [
        { value: '70907', text: 'E-mailing reçu [auto]' },
        { value: '70637', text: 'Panier du jour [auto]' },
        { value: '70640', text: 'Vu ce jour [auto]' },
        { value: '70641', text: 'Vu en j-1 [auto]' },
        { value: 'restrictfav', text: 'Vos favoris' },
      ],
      selectTypecv: [
        { value: 'E', text: 'Salariés' },
        { value: 'S', text: 'Stagiaires' },
        { value: 'F', text: 'Freelances' },
        { value: 'I', text: 'Intercontrats' },
      ],
      selectPopulation: [
        { value: '-10', text: 'BAC+1 et moins' },
        { value: '-11', text: 'BAC+2' },
        { value: '-12', text: 'BAC+3' },
        { value: '-13', text: 'BAC+4' },
        { value: '-14', text: 'BAC+5' },
        { value: '-15', text: 'BAC+6 et plus' },

        { value: '-20', text: 'XP : - de 1 an' },
        { value: '-21', text: 'XP : 1-2 ans' },
        { value: '-22', text: 'XP : 2-4 ans' },
        { value: '-23', text: 'XP : 5-10 ans' },
        { value: '-24', text: 'XP : 10-15 ans' },
        { value: '-25', text: 'XP : + de 15 ans' },

        { value: '-40', text: 'Profil : ingénieur' },
        { value: '-41', text: 'Profil : technicien' },
      ],
      tjm: 1200,
      tjmText: '',
      tjmEnabled: false,
      tjmStep: 25,
      tjmMin: 250,
      tjmMax: 1200,
      brut: 98,
      brutText: '',
      brutEnabled: false,
      brutStep: 2,
      brutMin: 18,
      brutMax: 98,

      data: {
        maxDailyCredits: '',
        mots: '',
        Poste: [],
        Region: 0,
        Region_m: 'M',
        Dossier: [],
        typecv: [],
        Population: [],
        Population_m: 'U',
        CVrecent: 0,
        tjm_min: 1200,
        sal_min: 98,
        // actions: '',
      },
    }),
    computed: {
      classes () {
        return {
          'v-btn--contained--launched': this.launched,
        }
      },
    },
    watch: {
      data (val, oldVal) {
        if (val === oldVal) return
        if (val.tjm_min !== 1200) {
          this.tjmEnabled = true
        }
        this.tjm = val.tjm_min

        if (val.sal_min !== 98) {
          this.brutEnabled = true
        }
        this.brut = val.sal_min
      },
      tjm (val, oldVal) {
        this.data.tjm_min = val
        if (val === oldVal) return
        if (this.tjmEnabled) {
          this.tjmText = val + '€'
        }
      },
      brut (val, oldVal) {
        this.data.sal_min = val
        if (val === oldVal) return
        if (this.brutEnabled) {
          this.brutText = val + 'k€'
        }
      },
      tjmEnabled (val, oldVal) {
        if (val === oldVal) return
        if (val === false) {
          this.tjm = 1200
          this.tjmText = ''
        }
      },
      brutEnabled (val, oldVal) {
        if (val === oldVal) return
        if (val === false) {
          this.brut = 98
          this.brutText = ''
        }
      },
    },
    mounted () {
      if (this.id !== '') {
        this.launched = true
        this.getLaunch(this.id)
      }
    },
    methods: {
      getLaunch (id) {
        LaunchesService.getLaunch(id).then(response => {
          this.data = response.data.params
          this.launched = false
        })
          .catch(error => {
            const message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString()
            // console.log(error)
            if (error.response && error.response.data && error.response.data.type) {
              this.alertType = error.response.data.type
            } else {
              this.alertType = 'error'
            }
            this.alertErrorText = message
            this.alertError = true
          })
      },
      launch () {
        if (this.$refs.form.validate()) {
          if (this.id !== '') {
            this.data._id = this.id
          }
          this.save()
        }
      },

      save () {
        this.launched = true
        LaunchesService.save(this.data).then(response => {
          this.launched = false
          this.alertSnackbar = true
          if (typeof response.data.message !== 'undefined') {
            this.alertSnackbarText = response.data.message
            this.alertSnackbarType = response.data.type
          }
        })
          .catch(error => {
            if (error.response && error.response.data && error.response.data.message) {
              this.alertErrorText = error.response.data.message
            } else if (error.message) {
              this.alertErrorText = error.message
            } else {
              const message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString()
              this.alertErrorText = message
            }
            if (error.response && error.response.data && error.response.data.type) {
              this.alertType = error.response.data.type
            } else {
              this.alertType = 'error'
            }
            // console.log(error)
            this.alertError = true
            this.launched = false
          })
      },
    },
  }
</script>

<style lang="sass">
  .theme--light.v-btn.v-btn--disabled.v-btn--contained--launched
    .v-icon
      color: black !important

  .v-btn--contained--launched
    .v-icon
      animation: shake 0.5s
      animation-iteration-count: infinite

  @keyframes shake
    0%
      transform: translate(1px, 1px) rotate(0deg)
    10%
      transform: translate(-1px, -1px) rotate(-1deg)
    20%
      transform: translate(-1px, 0px) rotate(1deg)
    30%
      transform: translate(1px, 1px) rotate(0deg)
    40%
      transform: translate(1px, -1px) rotate(1deg)
    50%
      transform: translate(-1px, 2px) rotate(-1deg)
    60%
      transform: translate(-1px, 0px) rotate(0deg)
    70%
      transform: translate(1px, 0px) rotate(-1deg)
    80%
      transform: translate(-1px, -1px) rotate(1deg)
    90%
      transform: translate(1px, 1px) rotate(0deg)
    100%
      transform: translate(1px, -1px) rotate(-1deg)

  // Reversed input variant
  .v-input--prepended
    .v-input__prepend-outer
      width: 100%
    .v-input__control
      width: auto
</style>
